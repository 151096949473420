<template>
  <div class="date-stat-excel">
    <div class="header">
      <el-form inline>
        <el-form-item label="标段" prop="tenderGuid">
          <el-select v-model="tenderGuid" clearable>
            <el-option v-for="tender of tenderList" :key="tender.tenderGuid" :label="tender.tenderName"
                       :value="tender.tenderGuid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年份">
          <el-input-number v-model="year" step-strictly :step="1" :min="minYear"></el-input-number>
        </el-form-item>
        <el-form-item label="月份">
          <el-input-number v-model="month" step-strictly :step="1" :min="1" :max="12"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleSearchClick">查询</el-button>
          <el-button @click="handleSaveClick">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div ref="excelContainer" class="excel-container"></div>
  </div>
</template>

<script>
import axios from 'axios'
import auth from '@/common/auth'
import config from '@/config'
import utility from '@/common/utility'

export default {
  name: 'TieFangDateStat',
  components: {},
  data () {
    return {
      year: new Date().getFullYear(),
      minYear: new Date().getFullYear() - 10,
      month: new Date().getMonth() + 1,
      tenderGuid: auth.getUserInfo().tenderGuid,
      tenderList: []
    }
  },
  computed: {},
  methods: {
    async handleSearchClick () {
      await this.init()
    },
    async handleSaveClick () {
      if (!this.workbook) {
        this.$message({
          message: '请先查询数据',
          type: 'warning'
        })
        return
      }
      // 导出excel
      const json = this.workbook.toJSON()
      this.excelIo.save(json, (blob) => {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        const filename = `铁方数据统计-${this.year}年${this.month}月.xlsx`
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
      })
    },
    async init () {
      this.$myLoading({})

      // 加载excel模版
      const excelTemplate = await axios.get(`${config.restHost}/g18/tiefang?tenderGuid=${this.tenderGuid}&year=${this.year}&month=${this.month}`, {
        responseType: 'blob'
      })
      this.excelIo = new GC.Spread.Excel.IO()
      this.excelIo.open(excelTemplate.data, async json => {
        if (!this.workbook) {
          this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
        }
        this.workbook.fromJSON(json)

        // 设置剪贴板模式
        this.workbook.getSheet(0).options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values

        this.$loading({}).close()
      })
    },
    async prepare () {
      const tenderListResponse = await axios.get(`${config.restHost}/api/tenders`, {
        params: {
          projectGuid: auth.getUserInfo().projectGuid
        },
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        }
      })
      this.tenderList = tenderListResponse.data
    }
  },
  async mounted () {
    await this.prepare()
  }
}
</script>

<style lang="scss" scoped>
.date-stat-excel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  box-sizing: border-box;

  .header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .excel-container {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
    margin: 0;
    box-sizing: border-box;
  }
}
</style>
